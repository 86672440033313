import axiosInstances from '@/libs/axios-instances'

export default function EntityReports() {
  const indexRequest = params => axiosInstances.entities.get('/internalops/entities/reports', { params }).then(res => res).catch(() => [])

  const toggleReadRequest = id => axiosInstances.entities.post(`/internalops/report/${id}/read`).then(res => res)

  const toggleFakeRequest = id => axiosInstances.entities.post(`/internalops/report/${id}/fake`).then(res => res)

  return {
    indexRequest, toggleReadRequest, toggleFakeRequest,
  }
}
