<template>
  <div>
    <b-card header="Search">
      <b-row>
        <b-col md="4">
          <b-form-group
            label="Entity Name"
            label-for="search"
          >
            <search
              placeholder="Name"
              :search-query.sync="query.entityName"
              @refresh="$refs.table.refresh()"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label="Date"
            label-for="date-range-filter"
          >
            <date-range-filter :date-range.sync="query.period" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <custom-vue-select
            id="status-filter"
            label="Status"
            placeholder="Select status"
            :value-reducer="option=>option.value"
            :options="[
              {text:'All',value:null},
              {text:'Read',value:true},
              {text:'Unread',value:false},
            ]"
            :selected.sync="query.read"
            text-field="text"
          />
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col md="2">
          <search-button @refresh="$refs.table.refresh()" />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-table
        ref="table"
        :items="getEntityReports"
        :fields="tableColumns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        show-empty
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showReasonModal(item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View Reason</span>
            </b-dropdown-item>
            <b-dropdown-item @click="toggleRead(item.id)">
              <b-checkbox
                v-model="item.isRead"
                switch
              >
                {{ item.isRead?'Read':'Unread' }}
              </b-checkbox>
            </b-dropdown-item>
            <b-dropdown-item @click="toggleFake(item.id)">
              <b-checkbox
                v-model="item.isFake"
                :value="false"
                :unchecked-value="true"
                switch
              >
                {{ item.isFake?'Fake Report':'True Report' }}
              </b-checkbox>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

    <b-modal
      id="report-reason-modal"
      title="Report Reason"
      centered
      ok-only
      @hidden="report={}"
    >
      <text-input
        id="report-reason"
        name="Reason"
        disabled
        :text.sync="report.content"
      />
    </b-modal>
  </div>
</template>

<script>
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import paginationData from '@/common/compositions/common/paginationData'
import EntityReports from '@/common/compositions/EntityReports/entityReportsApi'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'EntityReports',
  components: {
    DateRangeFilter,
    CustomVueSelect,
    Search,
    SearchButton,
    Pagination,
    TextInput,
  },
  data() {
    return {
      query: {},
      report: {},
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'user.profile', formatter: v => `${v.first_name} ${v.last_name}`, label: 'Reporter' },
        { key: 'user.email', label: 'email' },
        { key: 'classifications', label: 'Entity Type' },
        'entityName',
        'reportDate',
        { key: 'isRead', formatter: v => (v ? 'Read' : 'Unread'), label: 'status' },
        'actions',
      ]
    },
  },
  setup() {
    const { indexRequest, toggleFakeRequest, toggleReadRequest } = EntityReports()
    const { pagination } = paginationData()
    const { successfulOperationAlert } = handleAlerts()
    return {
      pagination, indexRequest, toggleReadRequest, toggleFakeRequest, successfulOperationAlert,
    }
  },
  methods: {
    getEntityReports() {
      return this.indexRequest(this.query).then(res => {
        this.pagination.totalRows = res.data.pagination?.total
        return res.data.data || []
      })
    },
    toggleRead(id) {
      this.toggleReadRequest(id).then(() => {
        this.successfulOperationAlert('Report read status is changed')
        this.$refs.table.refresh()
      })
    },
    toggleFake(id) {
      this.toggleFakeRequest(id).then(() => {
        this.successfulOperationAlert('Report type is changed')
        this.$refs.table.refresh()
      })
    },
    showReasonModal(item) {
      this.$bvModal.show('report-reason-modal')
      this.report = item
    },
  },
}
</script>

<style>

</style>
